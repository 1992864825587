import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Header } from "./components/Header";
import { Home } from "./components/Home";
import { Footer } from "./components/Footer";
import { SingleStation } from "./components/SingleStation";
import { RemovedList } from "./components/RemovedList";
import { MapList } from "./components/MapList";

// static pages
import { AboutPage } from "./components/AboutPage";
import { KMLPage } from "./components/KMLPage";
import { POIPage } from "./components/POIPage";

import "./App.css";

function App() {

    // App state variables
    const [stateCode, setStateCode] = useState(null); // current state/province code
    const [stationId, setStationId] = useState(null); // current station ID
    const [checkedStations, setCheckedStations] = useState([]); // current list of stations for printing
    const [printStations, setPrintStations] = useState(false); // flags printing of stations
    
    // UI logic
    const [shownComponents, setShownComponents] = useState({
        showUpdateForm: false,
        showRemoveForm: false,
        showAddForm: false,
        showMiniMap: true
    });

    // set the selected state
    function onStateClicked({ target }) {
        setStateCode(target.value);
        setShownComponents({
            showAddForm: false,
        });
    }
    
    // toggle the selected station
    function onViewButtonClicked({ target }) {
        if (stationId===target.value) {
            setStationId(null);
        } else {
            setStationId(target.value);
        }
        setShownComponents({
            showAddForm: false,
            showUpdateForm: false,
            showRemoveForm: false,
            showMiniMap: true,
        });
    }
    
    // show the update form
    function onUpdateButtonClicked() {
        setShownComponents({
            showUpdateForm: true,
            showRemoveForm: false,
            showMiniMap: false,
        });
    }
    
    // show the remove form
    function onRemoveButtonClicked() {
        setShownComponents({
            showUpdateForm: false,
            showRemoveForm: true,
            showMiniMap: false,
        });
    }
    
    // toggle the station add form
    function onAddButtonClicked() {
        setStationId(null);
        setShownComponents({
            showUpdateForm: false,
            showRemoveForm: false,
            showAddForm: !shownComponents.showAddForm,
            showMiniMap: false,
        });
    }
    
    // remove the update, remove, or add form
    function onCancel() {
        setShownComponents({
            showUpdateForm: false,
            showRemoveForm: false,
            showAddForm: false,
            showMiniMap: true,
        });
    }

    // add a station to the checked list
    function onStationChecked({ target }) {
        var updatedList = [...checkedStations];
        if (target.checked) {
            updatedList = [...checkedStations, target.value];
        } else {
            updatedList.splice(checkedStations.indexOf(target.value), 1);
        }
        setCheckedStations(updatedList);
    }

    // toggle printing of stations
    function onPrintStationsClicked() {
        setPrintStations(true);
    }

    // render the page
    return (
        <div>
            {!printStations &&
             <Header />
            }
            <Routes>
                <Route path="/" element={
                           <Home stateCode={stateCode} stationId={stationId}
                                 shownComponents={shownComponents}
                                 onStateClicked={onStateClicked} onViewButtonClicked={onViewButtonClicked}
                                 onAddButtonClicked={onAddButtonClicked} onUpdateButtonClicked={onUpdateButtonClicked} onRemoveButtonClicked={onRemoveButtonClicked} onCancel={onCancel}
                                 checkedStations={checkedStations} onStationChecked={onStationChecked} onPrintStationsClicked={onPrintStationsClicked} printStations={printStations} />
                       } />
                <Route path="/:id" element={
                           <Home shownComponents={shownComponents}
                                 onStateClicked={onStateClicked} onViewButtonClicked={onViewButtonClicked}
                                 onAddButtonClicked={onAddButtonClicked} onUpdateButtonClicked={onUpdateButtonClicked} onRemoveButtonClicked={onRemoveButtonClicked} onCancel={onCancel}
                                 checkedStations={checkedStations} onStationChecked={onStationChecked} onPrintStationsClicked={onPrintStationsClicked} printStations={printStations} />
                       } />
                <Route path="/station/:id" element={
                           <SingleStation shownComponents={shownComponents}
                                          onUpdateButtonClicked={onUpdateButtonClicked} onRemoveButtonClicked={onRemoveButtonClicked} onCancel={onCancel} />
                       } />
                <Route path="/removed" element={
                           <RemovedList stationId={stationId} 
                                        shownComponents={shownComponents}
                                        onViewButtonClicked={onViewButtonClicked} onUpdateButtonClicked={onUpdateButtonClicked} onCancel={onCancel} />
                       } />
                <Route path="/maps" element={
                           <MapList stateCode={stateCode} onStateClicked={onStateClicked} />
                       } />
                <Route path="/maps/:id" element={
                           <MapList stateCode={stateCode} onStateClicked={onStateClicked} />
                       } />                           
                <Route path="/about" element={ <AboutPage /> } />
                <Route path="/kml" element={ <KMLPage /> } />
                <Route path="/poi" element={ <POIPage /> } />
            </Routes>
            {!printStations &&
             <Footer />
            }
        </div>
    );
}

export default App;
